import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {VideoCameraOutlined} from '@ant-design/icons';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import {prependTwoZero} from '../../Utils/TimeUtil';

function LiveStreamList(props) {
  const [user, setUser] = useOutlet('user');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [paidProducts, setPaidProducts] = React.useState(null);
  const [paidLiveStreams, setPaidLiveStreams] = React.useState(null);

  React.useEffect(() => {
    async function fetchPaidProducts() {
      AppActions.setLoading(true);

      try {
        let _orders = await JStorage.fetchAllDocuments('order', {
          owner: user.sub,
          payment_status: 'success',
        });

        let productIds = [];
        for (const order of _orders) {
          const {items} = order;

          for (const item of items) {
            if (item.product.type === 'n_in_1') {
              for (const id of item.product.n_in_1_products) {
                productIds.push(id);
              }
            } else {
              productIds.push(item.product.id.$oid);
            }
          }
        }

        let _products = await JStorage.fetchAllDocuments(
          'product',
          {
            _id: {$in: productIds.map((id) => ({$oid: id}))},
          },
          ['-session.date', '-session.start_time'],
        );

        let _live_streams = await JStorage.fetchAllDocuments('live_stream', {
          product: {$in: productIds},
        });

        setPaidProducts(
          _products.filter((product) => {
            let hasLiveStream =
              _live_streams.findIndex(
                (_live_stream) => _live_stream.product === product.id,
              ) > -1;

            return hasLiveStream;
          }),
        );
        setPaidLiveStreams(_live_streams);
      } catch (err) {
        console.warn(err);
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (user && !user.tmp) {
      fetchPaidProducts();
    }
  }, [user]);

  return (
    <Wrapper mobile={mobile}>
      <div className="maxwidth-container">
        <div className="container">
          <h1 className="title">北律雲-直播串流</h1>

          {(paidProducts || []).map((product, index) => (
            <div className="item" key={`product-${index}`}>
              <div className="container">
                <div className="name">{product.name}</div>
                <div className="time">
                  直播時間：{product.session.date} {product.session.start_time}-
                  {product.session.end_time}
                </div>
              </div>

              <Button
                ghost
                type="primary"
                icon={
                  <VideoCameraOutlined size={18} color="var(--primaryColor)" />
                }
                className="play"
                onClick={() => {
                  let liveStreamId = paidLiveStreams.find(
                    (live_stream) => live_stream.product === product.id,
                  ).id;
                  AppActions.navigate(`/live_stream?id=${liveStreamId}`);
                }}>
                <label>觀看直播</label>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: var(--pageBackgroundColor);

  & > .maxwidth-container {
    margin: var(--topNavBarHeight) auto 0px;
    max-width: var(--contentMaxWidth);
    width: 100%;
    min-height: 100vh;
    padding: 20px;

    display: flex;
    justify-content: center;

    & > .container {
      max-width: 910px;
      width: 100%;
      margin: 30px auto;

      & > .title {
        margin-bottom: 10px;
      }

      & > .subtitle {
        margin-bottom: 10px;
        font-weight: 500;
        color: var(--primaryColor);
      }

      & > .item {
        margin-bottom: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
        justify-content: space-between;
        align-items: ${(props) => (props.mobile ? 'start' : 'center')};
        background-color: white;

        & > .container {
          margin-bottom: ${(props) => (props.mobile ? '10px' : '0px')};
          & > .name {
            margin-bottom: 5px;
            font-size: 1rem;
            font-weight: 600;
          }

          & > .time {
            color: var(--secondColor);
          }
        }

        & > .play {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          cursor: pointer;

          & > label {
            margin-left: 5px;
          }
        }
      }
    }
  }
`;

export default withPageEntry(LiveStreamList);
